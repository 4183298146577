'use strict';

module.exports = {
    gtmPageView: function () {
        var pageName = $('.gtmPageView').data('pagename');
        var pageType = $('.gtmPageView').data('pagetype');
        var level1 = $('.gtmPageView').data('level1');
        var level2 = $('.gtmPageView').data('level2');
        var region = $('.gtmPageView').data('region');
        var language = $('.gtmPageView').data('language');
        var selectedShop = $('.headerselectstore').data('preferredstoreid');

        var customerRegistered = $('.gtmPageView').data('customerregistered');
        var customerID = $('.gtmPageView').data('customerid');
        var customerMemberCard = $('.gtmPageView').data('customermembercard');

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'page_view',
            page: {
                page_name: pageName,
                page_type: pageType,
                level1: level1 ? level1 : pageName,
                level2: level2 ? level2 : 'undefined',
                level3: 'undefined',
                site_region: region,
                site_language: language,
            },
            user: {
                user_status: customerRegistered ? 'loggato' : 'non loggato',
                user_custom_id: customerID ? customerID : 'undefined',
                is_member: customerMemberCard ? 'si' : 'no',
                selected_shop: selectedShop ? selectedShop : 'undefined',
            },
        });
    },
};
